import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

import facebookSvg from '../assets/images/icons/facebook.svg';
import smeSvg from '../assets/images/icons/SME.svg';
import directionsSvg from '../assets/images/icons/directions.svg';
import logoPng from '../assets/images/icons/Little Explorers Daycare_Logo.png';
import '../styles/header.css';

function Header() {
  return (
    <div>
      <nav className="border-bottom" style={{ background: '#FAF3F3' }}>
        <div
          className="d-flex justify-content-end social-links-header"
          style={{
            height: '52px',
          }}
        >
          <ul className="nav" style={{ alignItems: 'center' }}>
            <li className="nav-item" style={{ height: '3rem' }}>
              <a
                href="https://www.facebook.com/profile.php?id=100084465500855&mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link link-dark px-2"
              >
                <img
                  src={facebookSvg}
                  alt=""
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '10px',
                  }}
                />
                Facebook
              </a>
            </li>
            <li className="nav-item" style={{ height: '3rem' }}>
              <a
                href="https://www.saskatchewan.ca/government/government-structure/ministries/education"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link link-dark px-2"
              >
                <img
                  src={smeSvg}
                  alt=""
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '10px',
                  }}
                />
                Saskatchewan Ministry of Education
              </a>
            </li>
            <li className="nav-item" style={{ height: '3rem' }}>
              <a
                href="https://www.google.com/search?q=little%20expolorers%20daycare%20tisdale&source=hp&ei=K1qyZP_uJ-mU2roP9ou8kAI&iflsig=AD69kcEAAAAAZLJoO52HOxjfiXsIhJrDJzWL8SJkrwOd&ved=0ahUKEwi_sJKDp5CAAxVpilYBHfYFDyIQ4dUDCAk&uact=5&oq=little%20expolorers%20daycare%20tisdale&gs_lp=Egdnd3Mtd2l6IiFsaXR0bGUgZXhwb2xvcmVycyBkYXljYXJlIHRpc2RhbGUyBhAAGBYYHkjJTVAAWNZLcAB4AJABAJgBuwGgAZgeqgEFMTAuMjO4AQPIAQD4AQHCAgsQABiKBRixAxiDAcICCxAAGIAEGLEDGIMBwgIIEAAYgAQYsQPCAggQLhiABBixA8ICCxAuGIMBGLEDGIAEwgILEC4YgAQYsQMYgwHCAgUQABiABMICCBAuGLEDGIAEwgIREC4YigUYsQMYgwEYxwEY0QPCAgsQLhiABBixAxjUAsICCxAuGNQCGLEDGIAEwgIIEAAYigUYsQPCAgsQLhiKBRixAxiDAcICBRAuGIAEwgILEC4YgAQYxwEYrwHCAgcQABiABBgKwgINEC4YgAQYxwEYrwEYCsICDRAuGA0YgAQYxwEYrwHCAgcQABgNGIAEwgIHEC4YDRiABMICDBAuGB4YDRjHARjRA8ICBhAAGB4YDcICDRAAGB4YDRgPGPEEGAo&sclient=gws-wiz"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link link-dark px-2"
              >
                <img
                  src={directionsSvg}
                  alt=""
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '10px',
                  }}
                />
                Get Directions
              </a>

            </li>
          </ul>
        </div>
      </nav>
      <header className="py-2 border-bottom">
        <div
          className="d-flex flex-column flex-md-row justify-content-center align-items-center header-link-row"
        >
          <Col className="d-flex align-items-start flex-column ">
            <Link
              to="/"
              className=""
            >
              <span className="fs-4">
                <img
                  src={logoPng}
                  style={{
                    width: '250px',
                    height: '67px',
                  }}
                  alt=""
                />
              </span>
            </Link>
          </Col>
          <ul
            className="nav secondary-links d-flex flex-column flex-md-row align-items-center justify-content-center"
          >
            <li className="nav-item">
              <Link to="/" className="nav-link active" aria-current="page">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link" onClick={() => window.scrollTo(0, 0)}>
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/programs" className="nav-link">
                Programs & Services
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/enrollment" className="nav-link">
                Enrolment Forms and Information
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <div className="button-group">
                <button
                  type="button"
                  className="btn btn-primary btn-header"
                  style={{
                    background: '#88C62C',
                    borderRadius: '30px',
                  }}
                >
                  <a
                    href="tel:+13068733261"
                    style={{
                      textDecoration: 'none',
                      color: 'white',
                    }}
                  >
                    +1 306-873-3261
                  </a>
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-header"
                  style={{
                    background: '#F57A1E',
                    borderRadius: '30px',
                  }}
                >
                  <a
                    href="tel:+13068527078"
                    style={{
                      textDecoration: 'none',
                      color: 'white',
                    }}
                  >
                    +1 306-852-7078
                  </a>
                </button>

              </div>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}

export default Header;
