import React from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import logoPng from '../assets/images/icons/Little Explorers Daycare_Logo.png';
import facebookSvg from '../assets/images/icons/facebook.svg';

import '../styles/footer.css';
// eslint-disable-next-line import/order
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Container fluid style={{ borderTop: '0.5em solid #88C62C' }}>
      <Row>
        <Col xs={12} md={12} lg={6} sm={12} style={{ background: '#FFFDF8' }}>
          <div className="footerRow">

            <Container fluid>
              <Row>
                <Col xs={12} md={5} className="logo-column">
                  <Link to="/">
                    <Image
                      src={logoPng}
                      className="footer-logo"
                      alt=""
                    />
                  </Link>

                  <ol style={{ paddingLeft: 0 }} className="my-2 logo-column-details">
                    <li className="list-group-item address_label">
                      <b>Address</b>
                    </li>
                    <li className="list-group-item">
                      <p>
                        717 100th St. Tisdale
                        {' '}
                        <br />
                        Saskatchewan, S0E 1T0
                      </p>

                    </li>

                    <li className="list-group-item">
                      <b>Phone</b>
                    </li>
                    <li className="list-group-item">
                      <p><b>+1 306-873-3261 </b></p>
                    </li>
                    <li className="list-group-item">
                      <b>Other Phone </b>
                    </li>

                    <li className="list-group-item other_phone">
                      <p><b>+1 306-852-7078</b></p>
                    </li>

                    <li className="list-group-item">
                      <b> Email:</b>
                    </li>

                    <li className="list-group-item email_address">
                      <p><b>Littleexplorer@sasktel.net</b></p>
                    </li>
                  </ol>
                </Col>

                <Col xs={12} md={6} className="links-column">

                  <b> Quick Links: </b>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/about" className="link-black-hover">About Us</Link>
                    </li>
                    <li>
                      <Link to="/programs" className="link-black-hover">Programs & Services</Link>
                    </li>
                    <li>
                      <Link to="/enrollment" className="link-black-hover">
                        Enrolment forms and
                        Information
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" className="link-black-hover">Contact Us</Link>
                    </li>
                  </ul>

                  <b>Follow Us: </b>
                  <div className="d-flex mb-2">
                    <a
                      href="https://www.facebook.com/profile.php?id=100084465500855&mibextid=LQQJ4d"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={facebookSvg} alt="" className="me-3" />
                    </a>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-between py-4 footer-copyright">
                <hr />
                <Col xs={12} md={6} className="text-md-start mb-2 mb-md-0">
                  <p>©2023 Little Explorers Daycare. All rights reserved.</p>
                </Col>
                <Col xs={12} md={6}>
                  <ul
                    className="list-unstyled d-flex flex-row justify-content-start justify-content-md-end"
                  >
                    <li className="me-3 me-md-0 mt-1 mt-md-0 border-right-md pr-4 mr-4">
                      <span
                        style={{ paddingRight: '10px' }}
                        className="footer-item"
                      >
                        Privacy Policy
                      </span>
                    </li>
                    <li className="me-3 me-md-0 mt-1 mt-md-0 border-right-md pr-4 mr-4">
                      <span style={{ paddingRight: '10px' }} className="footer-item">Sitemap</span>
                    </li>
                    <li className="me-3 me-md-0 mt-1 mt-md-0 pr-4">
                      <span
                        style={{ paddingRight: '10px' }}
                        className="footer-item"
                      >
                        Disclaimer
                      </span>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col xs={12} md={12} lg={6}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              title="Google Maps"
              className="embed-responsive-item"
              style={{
                width: '100%',
                height: '605px',
              }}
              src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=717%20100th%20St.%20Tisdale%20Saskatchewan,%20S0E%201T0+(Little%20Explorers%20Daycare)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/distance-area-calculator.html">
                measure distance on
                map
              </a>
            </iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
