import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router, Routes, Route, useLocation,
} from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/fonts.css';
import MobileHeader from './components/MobileHeader';

const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const Programs = React.lazy(() => import('./pages/Programs'));
const Enrollment = React.lazy(() => import('./pages/Enrollment'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Educators = React.lazy(() => import('./pages/Educators'));
const AssistantEducators = React.lazy(() => import('./pages/AssistantEducators'));

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};

function App() {
  const windowWidth = useWindowWidth();
  const isMobileView = windowWidth <= 780;

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <Router>
      <React.Suspense fallback={<div />}>
        <ScrollToTop />
        <div className="App">
          {isMobileView ? <MobileHeader /> : <Header />}
          <div style={{ marginTop: isMobileView ? '157px' : '0' }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/programs" element={<Programs />} />
              <Route path="/enrollment" element={<Enrollment />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/assistant-educators" element={<AssistantEducators />} />
              <Route path="/educators" element={<Educators />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </React.Suspense>
    </Router>
  );
}

export default App;
