// MobileHeader.js
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
// eslint-disable-next-line import/order
import logoPng from '../assets/images/icons/Little Explorers Daycare_Logo.png';
import '../styles/mobile-header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../styles/fonts.css';
import facebookSvg from '../assets/images/header/facebook.svg';
import smeSvg from '../assets/images/header/sme.png';
import directionsSvg from '../assets/images/header/Directions.svg';

function MobileHeader() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div>
      <header className="mobile-header py-3 border-bottom-mobile">
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <ul
            className="nav d-flex"
            style={{
              alignItems: 'center',
              width: '100%',
            }}
          >
            <li className="nav-item">
              <a
                href="https://www.facebook.com/profile.php?id=100084465500855&mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebookSvg}
                  alt=""
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '5px',
                    marginLeft: '5px',
                  }}
                />
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.saskatchewan.ca/government/government-structure/ministries/education"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={smeSvg}
                  alt=""
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '5px',
                    marginLeft: '5px',
                  }}
                />
              </a>
            </li>
            <li className="nav-item">
              <Link to="/">
                <img
                  src={directionsSvg}
                  alt=""
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '5px',
                    marginLeft: '5px',
                  }}
                />
              </Link>
            </li>
            <li
              className="nav-item"
              style={{
                height: '43px',
                marginLeft: 'auto',
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#88C62C',
                marginRight: '15px',
              }}
              >
                <FontAwesomeIcon icon={faPhone} color="white" />
              </div>
            </li>
          </ul>
        </div>
        <hr />
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <Link to="/" className="d-flex align-items-center text-dark text-decoration-none">
            <span className="fs-4">
              <img
                src={logoPng}
                style={{
                  width: '159px',
                  height: '43px',
                  marginLeft: '10px',
                }}
                alt=""
              />
            </span>
          </Link>
          <FontAwesomeIcon
            icon={isNavOpen ? faTimes : faBars}
            className={`navbar-toggler ${isNavOpen ? 'collapsed' : ''}`}
            onClick={handleNavToggle}
            aria-controls="mobileNav"
            aria-expanded={isNavOpen ? 'true' : 'false'}
            style={{
              cursor: 'pointer',
              marginRight: '15px',
              height: '43px',
            }}
          />
          <div
            className={`mt-3 collapse navbar-collapse ${isNavOpen ? 'show' : ''}`}
            id="mobileNav"
          >
            <ul className="navbar-nav ms-auto mb-lg-0">
              <li className="nav-item">
                <NavLink
                  exact
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                  style={{ textTransform: 'uppercase' }}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about"
                  className="nav-link"
                  activeClassName="active"
                  style={{ textTransform: 'uppercase' }}
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/programs"
                  className="nav-link"
                  activeClassName="active"
                  style={{ textTransform: 'uppercase' }}
                >
                  Programs & Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/enrollment"
                  className="nav-link"
                  activeClassName="active"
                  style={{ textTransform: 'uppercase' }}
                >
                  Enrolment Forms and Information
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contact"
                  className="nav-link"
                  activeClassName="active"
                  style={{ textTransform: 'uppercase' }}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
}

export default MobileHeader;
